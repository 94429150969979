<template>
	<div class="page">
		<Head :headTitle="headTitle" />
		<div class="container">
			<div class="con-l">
				<div class="mod1 flex flex-v">
					<div class="s-pub-tt flex flex-pack-justify">
						<span class="bt">融媒体中心传输通道</span>
						<span class="wz">异常监测：<em class="c-red">6</em>项</span>
					</div>
					<ul class="mod1-list flex-1">
						<li>
							<div class="tit">客户端</div>
							<div class="txt">
								<span><i class="c-red">2</i>/158</span>项
							</div>
						</li>
						<li>
							<div class="tit">报刊</div>
							<div class="txt">
								<span>50</span>项
							</div>
						</li>
						<li>
							<div class="tit">广播</div>
							<div class="txt">
								<span>62</span>项
							</div>
						</li>
						<li>
							<div class="tit">电视</div>
							<div class="txt">
								<span>5,802</span>项
							</div>
						</li>
						<li>
							<div class="tit">大喇叭</div>
							<div class="txt">
								<span>803</span>项
							</div>
						</li>
						<li>
							<div class="tit">网站</div>
							<div class="txt">
								<span>8,602</span>项
							</div>
						</li>
						<li>
							<div class="tit">微信</div>
							<div class="txt">
								<span><i class="c-red">4</i>/261</span>项
							</div>
						</li>
						<li>
							<div class="tit">微博</div>
							<div class="txt">
								<span>8,602</span>项
							</div>
						</li>
						<li>
							<div class="tit">公众人物</div>
							<div class="txt">
								<span>61</span>项
							</div>
						</li>
						<li>
							<div class="tit">网红</div>
							<div class="txt">
								<span>1,682</span>项
							</div>
						</li>
					</ul>
				</div>
				<div class="mod2 flex flex-v">
					<div class="s-pub-tt flex">
						<span class="bt">本月数据</span>
					</div>
					<ul class="mod1-list flex-1">
						<li>
							<div class="tit">本月要闻</div>
							<div class="txt">
								<span>158</span>篇
							</div>
						</li>
						<li>
							<div class="tit">现场记者</div>
							<div class="txt">
								<span>50</span>名
							</div>
						</li>
						<li>
							<div class="tit">新增稿件</div>
							<div class="txt">
								<span>62</span>篇
							</div>
						</li>
						<li>
							<div class="tit">新增阅读量</div>
							<div class="txt">
								<span>5,802</span>项
							</div>
						</li>
					</ul>
				</div>
				<div class="mod3">
					<div class="s-pub-tt flex">
						<span class="bt">县城热点事件</span>
					</div>
					<ul class="mod3-list">
						<li class="top">
							<div class="xh">排名</div>
							<div class="title">新闻热点事件</div>
							<div class="date">发生时间</div>
							<div class="source">新闻来源</div>
						</li>
					</ul>
					<ul class="mod3-list">
						<li class="item" v-for="(item,index) in hotList" :key="index">
							<span class="xh">{{index + 1}}</span>
							<h6 class="title ellipsis">{{item.title}}</h6>
							<div class="date">{{item.date}}</div>
							<div class="source">{{item.source}}</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="con-c flex flex-v">
				<div class="mod4 flex-1 flex flex-v">
					<div class="s-pub-tt flex">
						<span class="bt">会议系统</span>
					</div>
					<div class="detail flex-1">
						<ul class="mod4-pics">
							<li class="item" v-for="(item,index) in xtPicList" :key="index">
								<img :src="item.img" class="img img_full" alt="">
							</li>
						</ul>
						<div class="mod4-big">
							<img src="../assets/images/img_04.gif" class="img img_full" alt="">
						</div>
					</div>
				</div>
				<div class="mod5">
					<div class="s-pub-tt flex">
						<span class="bt">传播监控</span>
					</div>
					<ul class="mod5-list">
						<li class="item" v-for="(item,index) in monitorList" :key="index">
							<img :src="item.img" class="img img_full" alt="">
							<h6 class="title ellipsis">记者:{{item.title}}</h6>
						</li>
					</ul>
					
				</div>
			</div>
			<div class="con-r flex flex-v">
				<div class="mod6">
					<div class="s-pub-tt flex">
						<span class="bt">舆情分析</span>
					</div>
					<ul class="mod6-list">
						<li class="item item1">
							<p class="fz">5,378条</p>
							<p class="fz">正面环比增幅</p>
							<p class="percent c-red">61% <img src="../assets/images/icon_03.png" class="icon" alt=""></p>
						</li>
						<li class="item item2">
							<p class="fz">1,479条</p>
							<p class="fz">负面环比增幅</p>
							<p class="percent c-lightgreen">39% <img src="../assets/images/icon_04.png" class="icon" alt=""></p>
						</li>
					</ul>
					
				</div>
				<div class="mod7">
					<div class="s-pub-tt flex">
						<span class="bt">县城舆情通</span>
					</div>
					<div class="detail">
						<div class="col">
							<h6 class="t-tit">政务舆情</h6>
							<ul class="list">
								<li class="ellipsis" v-for="(item,index) in zwList" :key="index">{{item.title}}</li>
							</ul>
						</div>
						<div class="col">
							<h6 class="t-tit">民生舆情</h6>
							<ul class="list">
								<li class="ellipsis" v-for="(item,index) in msList" :key="index">{{item.title}}</li>
							</ul>
						</div>
					</div>
					
				</div>
				<div class="mod8 flex-1 flex flex-v">
					<div class="s-pub-tt flex">
						<span class="bt">栏目分析</span>
					</div>
					<div class="s-pub-smtit">
						<span class="bt">用户年龄分析</span>
						<span class="line"></span>
						<i class="arrow"></i>
					</div>
					<div class="chart" ref="chart1"></div>
					
					<div class="s-pub-smtit">
						<span class="bt">栏目浏览趋势</span>
						<span class="line"></span>
						<i class="arrow"></i>
					</div>
					<div class="menu">
						<span class="lk cur">新闻类</span>
						<span class="lk">教育类</span>
						<span class="lk">文艺类</span>
						<span class="lk">体育类</span>
						<span class="lk">财经类</span>
						<span class="lk">服务类</span>
						<span class="lk">文化娱乐类</span>
					</div>
					<div class="chart" ref="chart2"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/head_sm.vue'
	let Echarts = require('echarts/lib/echarts');
	import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
	export default {
		data() {
			return {
				headTitle: '龙胜县智慧融媒体展示中心',
				hotList:[{
					title:'县运会武术套路比赛比赛比赛比县运会武术套路比赛比赛比赛比',
					date:'2021-11-23',
					source:'人民日报',
				},{
					title:'县运会武术套路比赛',
					date:'2021-11-23',
					source:'人民日报',
				},{
					title:'县运会武术套路比赛',
					date:'2021-11-23',
					source:'人民日报',
				},{
					title:'县运会武术套路比赛',
					date:'2021-11-23',
					source:'人民日报',
				},{
					title:'县运会武术套路比赛',
					date:'2021-11-23',
					source:'人民日报',
				},{
					title:'县运会武术套路比赛',
					date:'2021-11-23',
					source:'人民日报',
				}],
				xtPicList:[{
					img:require('../assets/images/img_02.gif')
				},{
					img:require('../assets/images/img_01.gif')
				},{
					img:require('../assets/images/img_03.gif')
				}],
				monitorList:[{
					img:require('../assets/images/img_01.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_02.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_03.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_04.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_01.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_02.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_03.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_04.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_01.gif'),
					title:'徐雪雪'
				},{
					img:require('../assets/images/img_02.gif'),
					title:'徐雪雪'
				}],
				zwList:[{
					title:'一季度利润总额同比增逾两成'
				},{
					title:'“套路贷”不是贷款，是犯罪'
				},{
					title:'全国破获毒品刑事案件14万起'
				},{
					title:'人民观点：锻造专业精进的“金刚钻”'
				},{
					title:'新媒观察：破除网上政务公开“梗阻”'
				}],
				msList:[{
					title:'全国破获毒品刑事案件14万起'
				},{
					title:'“套路贷”不是贷款，是犯罪'
				},{
					title:'一季度利润总额同比增逾两成'
				},{
					title:'人民观点：锻造专业精进的“金刚钻”'
				},{
					title:'新媒观察：破除网上政务公开“梗阻”'
				}],
				tootipTimer1:"",
				tootipTimer:"",
				chart1: null,
				chart2: null,
			}
		},
		components: {
			Head

		},
		computed: {

		},
		created() {

		},
		mounted() {
			this.init1();
			this.init2();
			
			window.addEventListener('resize', () => {
				this.chart1.resize();
				this.chart2.resize();
			});
		},
		
		methods: {
			init1() {
				this.chart1 = Echarts.init(this.$refs.chart1);
				const chartData = [
					{
						value: 12,
						name: '10-20岁',
					},
					{
						value: 29,
						name: '20-30岁'
					},
					{
						value: 31,
						name: '30-40岁'
					},
					{
						value: 18,
						name: '40-50岁'
					},
					{
						value: 60,
						name: '50-60岁'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: `{a|主要用户}\n\n{b|40-50岁}`,
						top: 'center',
						textStyle: {
							 rich: {
								a: {
								   fontSize: nowSize(30),
								   padding: [nowSize(-10), 0, 0, nowSize(160)],
								   color: '#fff',
								   lineHeight:1,
								   fontFamily:'PangMenZhengDao'
								},
								b: {
								   fontSize: nowSize(36),
								   padding: [0, 0, 0, nowSize(148)],
								   color: '#45F5FF',
								   lineHeight:1,
								    fontFamily:'PangMenZhengDao'
								}
							}
						}
				   },
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(24),
						itemHeight: nowSize(24),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24),
							lineHeight: nowSize(40)
						},
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '人}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(24),
								   width: nowSize(230),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								value: {
								   fontSize: nowSize(24),
								   width: nowSize(110),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								percent: {
								   fontSize: nowSize(24),
								   width: nowSize(110),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#00B7FF', '#02B581', '#FFC71A', '#FD3728', '#0060DB'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['65%', '80%'],
						center: ['22%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data:chartData
					},{
						 type: 'pie',
						 name: '内层细圆环',
						radius: ['55%', '57%'],
						center: ['22%', '48%'],
						 hoverAnimation: false,
						 clockWise: true,
						 itemStyle: {
							normal: {
							   color: '#178ac9',
							},
						 },
						 tooltip: {
							show: false,
						 },
						 label: {
							show: false,
						 },
						 data: [1],
					}]
			
				};
				this.chart1.setOption(option, true);
				
				
				
				this.tootipTimer1 && this.tootipTimer1.clearLoop();
				this.tootipTimer1 = 0;
				this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
			},
			
			
			init2() {
				this.chart2 = Echarts.init(this.$refs.chart2);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: '0',
						right: nowSize(20),
						bottom: 0,
						top: nowSize(80),
						containLabel: true
					},
					
					legend: {
						top: '0',
						left: 'center',
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24)
						},
						itemWidth:nowSize(22),
						itemHeight:nowSize(8),
						icon:'rect'
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['15:00', '16:00', '17:00', '18:00','19:00','20:00' ],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(24)
							},
							//rotate: '0',
							interval: 0
						},
						axisLine: {
							lineStyle: {
								color: '#e5e5e5' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 60000,
						interval: 10000,
						nameTextStyle: {
							color: "#8ea1ba",
							fontSize: nowSize(14),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(24), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
			
					series: [{
						name: '10-20岁',
						type: 'line',
						data: [884,1326,2652,7955,11048,20329],
						smooth: true,
						symbolSize: 2, //折线点的大小
						itemStyle: {
							normal: {
								color: "#2CCDFB", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#2CCDFB" //折线的颜色
			
								}
							}
						}
					},{
						name: '20-30岁',
						type: 'line',
						data: [2136,3204,6408,19224,26700,49129],
						smooth: true,
						symbolSize: 2, //折线点的大小
						itemStyle: {
							normal: {
								color: "#02B581", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#02B581" //折线的颜色
			
								}
							}
						}
					},{
						name: '30-40岁',
						type: 'line',
						data: [2283,3425,6850,20550,28542,52517],
						smooth: true,
						symbolSize: 2, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FFC71A", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FFC71A" //折线的颜色
			
								}
							}
						}
					},{
						name: '40-50岁',
						type: 'line',
						data: [1326,1989,3977,11932,16573,30494],
						smooth: true,
						symbolSize: 2, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD3728", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD3728" //折线的颜色
			
								}
							}
						}
					},{
						name: '50-60岁',
						type: 'line',
						data: [737,1105,2210,6629,9207,16941],
						smooth: true,
						symbolSize: 2, //折线点的大小
						itemStyle: {
							normal: {
								color: "#0060DB", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#0060DB" //折线的颜色
			
								}
							}
						}
					}]
			
				};
				this.chart2.setOption(option, true);
				
				this.tootipTimer && this.tootipTimer.clearLoop(); 
				this.tootipTimer = 0;
				this.tootipTimer = loopShowTooltip(this.chart2, option, {});
				
			},
			
			
				
				
		}
	}
</script>

<style scoped>
.page{background: #000;display: flex;flex-direction: column;height: 100%;position:fixed;width: 100%;left: 0;top: 0;}
.con-l{display: flex;flex-direction: column;}
/* .container{flex:1;overflow: hidden;margin:20px 40px 40px;display: flex;justify-content: space-between;}
.con-l{width: 1040px;display: flex;flex-direction: column;}
.con-c{width: 1842px;}
.con-r{width: 1040px;} */
	
.mod1{height: 46.5%;}
.mod1-list{display: flex;flex-wrap: wrap;justify-content: space-between;}
.mod1-list li{background-color: #08172E;height: 115px;width: 48.5%;margin-bottom: 3%;display: flex;align-items: center;justify-content: space-between;padding:0 40px;font-size: 32px;color:#fff;}
.mod1-list .tit{font-size: inherit;}
.mod1-list .txt{font-size: inherit;}
.mod1-list .txt span{font-size: 40px;color:#45F5FF;font-family: 'simpds';padding-right: 8px;}
.mod1-list .txt i{font-family: 'simpds';}

.mod2{padding-bottom: 10px;height: 22%;}

.mod3-list{}
.mod3-list li{display: grid;grid-template-columns:15% 45% 20% 18%;align-items: center;width: 100%;text-align: center;}
.mod3-list li>div{padding:0 10px;}
.mod3-list li *{font-size: inherit;}
.mod3-list .title{text-align: left;}
.mod3-list .top{height: 50px;background-color: #0A1E3E;font-size: 30px;color:#fff;margin-bottom: 15px;}
.mod3-list .item{height: 68px;font-size: 26px;color:#fff;}
.mod3-list .item:nth-child(odd){background-image: linear-gradient(-45deg,#06285f 25%,transparent 25%,transparent 50%,#06285f 50%,#06285f 75%,transparent 75%,transparent);background-size:20px 20px;}
.mod3-list .item:nth-child(1) .xh{color:#FD9D20;}
.mod3-list .item:nth-child(2) .xh{color:#FD9D20;}
.mod3-list .item:nth-child(3) .xh{color:#FD9D20;}
.mod3-list .item .xh{font-family: 'YouSheBiaoTiHei';}


.mod4{margin-bottom: 40px;}
.mod4 .detail{display: flex;}
.mod4-pics{width: 380px;margin-right: 30px;display: grid;grid-template-rows: 1fr 1fr 1fr;grid-gap: 35px;height: 100%;}
.mod4-pics .item{width: 100%;/* height: 300px; */}

.mod4-big{flex:1;overflow: hidden;height: 100%;}

.mod5-list{display: grid;grid-template-rows: 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;grid-gap: 40px;height: 680px;}
.mod5-list .item{position:relative;/* width: 330px; */}
.mod5-list .title{font-size: 32px;color:#fff;line-height: 60px;height: 60px;padding:0 15px;background-color: rgba(0,0,0,.64);position:absolute;width: 100%;left: 0;bottom: 0;}

.mod6{}
.mod6-list{display: flex;justify-content: space-between;padding:0 60px;}
.mod6-list .item{width:378px;height:270px;padding:60px 0 0 40px;}
.mod6-list .fz{font-size: 30px;color:#fff;line-height: 42px;}
.mod6-list .percent{font-size: 62px;line-height: 1;font-family: 'PangMenZhengDao';margin-top: 10px;display: flex;align-items: center;}
.mod6-list .item1{background:url(../assets/images/jia.png) no-repeat center;background-size:100% 100%;}
.mod6-list .item2{background:url(../assets/images/jian.png) no-repeat center;background-size:100% 100%;}
.mod6-list .icon{width: 22px;margin-left: 5px;}

.mod7{margin-bottom: 20px;}
.mod7 .detail{display: flex;justify-content: space-between;}
.mod7 .col{background: #0A1E3E;padding: 20px;width: 48.5%;}
.mod7 .t-tit{font-size: 30px;color:#45F5FF;margin-bottom: 8px;}
.mod7 .list{}
.mod7 .list li{font-size: 26px;color:#fff;line-height: 40px;}
.mod7 .list li::before{content:'';display: inline-block;vertical-align: middle;width: 8px;height: 8px;background-color: #45F5FF;border-radius:50%;margin-right: 15px;}

.mod8{}
.mod8 .menu{display: flex;justify-content: space-between;margin-bottom: 10px;}
.mod8 .menu .lk{font-size: 30px;color:#707070;line-height: 40px;cursor: default;}
.mod8 .menu .lk.cur{color:#3FF5FF;}


.chart{height:calc(50% - 150px);}
</style>
